import type { Page as PageType } from 'src/types/page';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';

import OverviewPage from 'src/pages/dashboard/overview/index';

const Page: PageType = () => <OverviewPage root={true} />;

Page.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default Page;
